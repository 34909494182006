// ./components/About.js

import React from 'react';
import Logo from '../assets/logo512.png'
import Newsletter from './Newsletter';

const About = () => {
  return (
    <div className='justify-center items-center pr-[50px] pl-[50px]'>
      <div className='flex items-center justify-center mt-[100px]'>
        <img className='flex w-[200px] h-[200px] items-center justify-center' src={Logo} alt='Gyrowin.org Referral Program'></img>
      </div>
      
      <div className='flex justify-center items-center'>
        <p className='text-[#00df9a] items-center justify-center text-5xl p-10 '>
            About the Gyrowin.org Referral Program
        </p>
      </div>

      <div className='max-w-[800px] mt-[20px] w-full h-max mx-auto text-left flex flex-col justify-center'>
      <br />
        <h2 className='text-[#b671f3] text-3xl font-bold mb-4'>A New Era in Decentralized Finance</h2>
        <p className='text-[#f8f8f8] text-2xl'>
            In the sprawling landscape of decentralized finance, Gyrowin has emerged as a pioneering platform, blending innovation with a user-centric approach. 
        </p> 
        <br />
        <h2 className='text-[#b671f3] text-3xl font-bold mt-6 mb-4'>Community at its Core</h2>
        <p className='text-[#f8f8f8] text-2xl'>
            Recognizing the power of collective growth, the gyrowin.org referral program was envisioned. As a natural extension of the Gyrowin ethos, this program isn't merely a tool for expansion, but a tribute to the community that fuels the Gyrowin universe.
        </p> 
        <br />
        <h2 className='text-[#b671f3] text-3xl font-bold mt-6 mb-4'>Beyond Rewards</h2>
        <p className='text-[#f8f8f8] text-2xl'>
            Built on cutting-edge technology, the gyrowin.org referral program isn’t just about tracking and rewards. It's an intricate system designed around trust. At gyrowin.org, we don’t just offer a referral program; we offer an experience. Each member is inducted into a journey, one where they're not just observers but active participants in Gyrowin's growth story.
        </p> 
        <br />
        <h2 className='text-[#b671f3] text-3xl font-bold mt-6 mb-4'>Our Commitment</h2>
        <p className='text-[#f8f8f8] text-2xl'>
            Our commitment extends beyond technology and rewards. It's rooted in transparency, growth, and a relentless pursuit of excellence.
        </p> 
        <br />
        <h2 className='text-[#b671f3] text-3xl font-bold mt-6 mb-4'>Join Our Journey</h2>
        <p className='text-[#f8f8f8] text-2xl pb-[300px]'>
            Join us at gyrowin.org as we journey forward, crafting a brighter, more inclusive future for decentralized finance, one referral at a time.
        </p>
        
      </div>
      <div className='border-t border-b border-[#87ffbd] animate-gradient transition-all duration-5000 ease-in-out'>
        <Newsletter />
        <style>
    {`
      @keyframes gradientChange {
        30% { background-color: #e8d1fa; }
        50% { background-color: #8442e1; }
        100% { background-color: #726a7e; }
      }

      .animate-gradient {
        animation-name: gradientChange;
        animation-duration: 20s;
        animation-iteration-count: infinite;
      }
    `}
  </style>
        </div>
    </div>
  );
}

export default About;
