// ./components/Documentation.js

import React from 'react';
import Logo from '../assets/logo512.png'

const Documentation = () => {
  return (
    <div className='justify-center items-center pr-[50px] pl-[50px]'>
    <div className='flex items-center justify-center mt-[100px]'>
        <img className='flex w-[200px] h-[200px] items-center justify-center' src={Logo} alt='Gyrowin Referrals Program'></img>
    </div>
    <div className='flex justify-center items-center'>

    <p className='text-[#00df9a] items-center justify-center text-5xl font-bold p-10 '>
    Gyrowin.org Referral Program:
        </p>
       
        
    </div>
    <div className='flex justify-center items-center'>

    <p className='text-[#fcf16f] font-bold text-3xl '>
    Amplifying the Gyrowin Experience
        </p>
       
        
    </div>

    <div className='max-w-[800px] mt-[20px] w-full h-max mx-auto text-left flex flex-col justify-center'>

            
    <br /><br />
            <p className='text-[#42d0ec] text-3xl'> Gyrowin.org Referral Program: </p>



            <p className='text-[#46cbfc] font-bold text-2xl'>  </p>
            <br />
            <p className='text-[#f8f8f8] text-2xl'>In the rapidly expanding world of decentralized finance, the Gyrowin platform stands out as a beacon of innovation and opportunity. At its core, Gyrowin offers a multitude of features, from token investments, decentralized finance protocols, lotteries, and more. But, as the saying goes, “Sharing is Caring”. This belief gave birth to the Gyrowin.org Referral Program, designed to reward those who spread the word about the exceptional offerings of Gyrowin.
           
            
        </p> <br /><br />

        <p className='text-[#46cbfc] text-2xl'> Why Join the Gyrowin.org Referral Program? </p>
        <br /><p className='text-[#f8f8f8] text-2xl'>
           
            1. Exclusive Benefits: Membership in our referral program isn’t just a title. It’s a gateway to an array of unique benefits, tailor-made to ensure our referrers feel valued. These privileges extend beyond mere rewards, encompassing special events, early access to new features, and more.
            <br /><br />
            2. Compound & Consistent Growth: Each referral is an investment in your future gains on the Gyrowin platform. With every individual you introduce, you're planting seeds that will mature over time. As they engage with various facets of Gyrowin, your rewards burgeon, fostering a consistent growth pattern.
            <br /><br />
            3. Become a Gyrowin Ambassador: Your active participation brands you as a Gyrowin ambassador. This title isn't just for show; it's an acknowledgment of your contribution to the community's growth. With this, you might gain access to exclusive community events, roundtable discussions, and even direct interactions with the Gyrowin development team.
            <br /><br />
            4. Community-Centric Approach: Gyrowin thrives on its community. By referring new members, you're not merely adding numbers. You're contributing to creating a diverse, informed, and engaged community. This, in turn, aids in making the Gyrowin ecosystem more robust and dynamic.
            <br/><br />
            5. Support Like Never Before: Our dedicated referral support system ensures that our ambassadors never feel lost. With prioritized response times and specialized assistance, all your referral-related queries are addressed with utmost importance.
         </p><br/><br />

         <p className='text-[#46cbfc] text-2xl'> Decoding the Referral Process </p><br />
         <p className='text-[#f0fc46] text-2xl'> Easy Registration, Unique Link:  </p>
                        
            <p className='text-[#f8f8f8] text-2xl'>
            Easy Registration, Unique Link: Begin by registering on gyrowin.org. Once done, you'll receive a unique referral link, your primary tool to tap into a world of rewards.
            <br /> <br />
            <p className='text-[#f0fc46] text-2xl'> Sharing is Rewarding: </p>
            
            Your networks are your net-worth. Share your unique link across diverse platforms, be it on social media, discussion forums, blog posts, or even direct interactions. Personalize your approach, narrate your positive experiences with Gyrowin, and let the world know why Gyrowin is the next big thing in DeFi.
        </p> <br />
        <p className='text-[#f8f8f8] text-2xl'>
        Rewards for Every Successful Referral: Each successful referral translates to rewards for you. But remember, while instant gratification is great, the real magic unfolds as your referrals dive deeper into Gyrowin. Their transactions, participation in lotteries, and other activities can potentially boost your earnings.
        </p><br />
        <p className='text-[#f8f8f8] text-2xl'>
        Keep an Eye on gyro.win: For detailed insights and performance metrics related to your referrals, gyro.win is your destination. Understand how your referrals are engaging with the platform, which can offer cues on refining your referral strategies.
        </p><br />
        <p className='text-[#f8f8f8] text-2xl'>
        Flexibility in Rewards: Your rewards, your choice. Whether you wish to withdraw them for immediate gains or reinvest within Gyrowin for potential future benefits, the decision rests with you.
        </p><br />
        <p className='text-[#f0fc46] text-2xl'> A Deep Dive into Gyrowin's Potential </p>
        <p className='text-[#f8f8f8] text-2xl'>
           
        Gyrowin isn't just another platform; it's an ecosystem. With features like the Money Plant vault, governance protocols, and partnerships with major protocols like Chainlink and The Graph, Gyrowin is setting the pace for DeFi's future. When you refer someone, you're not just introducing them to a platform but a thriving ecosystem.
           <br /><br />
           Moreover, Gyrowin's commitment to societal good, as seen through its donation initiatives, paints a picture of a platform that cares. This isn't just about finance; it's about building a sustainable future. Your referrals play a pivotal role in this vision.
           <br /><br />
           
        </p><br/>
        
            <p className='text-[#f0fc46]text-2xl'> Concluding Thoughts</p>
       
        
        <p className='text-[#f8f8f8] text-2xl pb-[300px]'>
        Gyrowin.org’s Referral Program is more than just an added feature. It's a testament to the belief that the true strength of a platform lies in its community. By referring others, you're not only boosting your rewards but also playing a crucial role in Gyrowin's journey towards defining the future of DeFi. Embark on this rewarding journey, let your networks in on the secret, and watch as the Gyrowin universe expands and flourishes!
        </p> 
        <br />


    </div>
</div>
  );
}

export default Documentation;