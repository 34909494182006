import React from 'react';
import Laptop from '../assets/WMCplugin.png';
import { Link } from 'react-router-dom';

const Analytics = () => {
  return (
    <div className='w-full bg-white py-16 px-4 animate-gradient transition-all duration-5000 ease-in-out'>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
        <img className='w-[400px] mx-auto my-4 float-effect hover:scale-110 transition-transform duration-300' src={Laptop} alt='/' />
        <div className='flex flex-col justify-center'>
          <p className='text-[#ababab] font-bold'>Invest and grow</p>
          <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'>Play and earn</h1>
          <p>
          Gyrowin: Redefining decentralized finance and gaming on leading blockchains. Dive into unique tokenomics, charitable lotteries, and a rewarding Money Plant function. With Chainlink & The Graph integrations, enjoy top-tier security. Join the revolution and amplify your gains with our referral program at gyrowin.org
            {/* Other content */}
          </p>
          <Link to="https://docs.google.com/forms/d/e/1FAIpQLSdUbprBLId0tIrM8_T6Xp4tky7AvGQG27mxGGICaV8-OeQUSg/viewform"><button className='bg-[#1c1c1c] text-[#3dffb1] w-[200px] rounded-md font-medium my-6 mx-auto md:mx-0 py-3'>Get Started</button></Link>
        </div>
      </div>
      <style>
        {`
          @keyframes floatEffect {
            0% { transform: translateY(0px); }
            50% { transform: translateY(-10px); }
            100% { transform: translateY(0px); }
          }

          @keyframes gradientChange {
            30% { background-color: #e8d1fa; }
            50% { background-color: #8442e1; }
            100% { background-color: #726a7e; }
          }

          .float-effect {
            animation: floatEffect 3s ease-in-out infinite;
          }

          .animate-gradient {
            animation-name: gradientChange;
            animation-duration: 20s;
            animation-iteration-count: infinite;
          }
        `}
      </style>
    </div>
  );
};

export default Analytics;
