import React from 'react';
import Logo from '../assets/logo512.png';
import {Link} from 'react-router-dom';
import {
  FaDribbbleSquare,
  FaFacebookSquare,
  FaGithubSquare,
  FaInstagram,
  FaTelegram,
  FaTelegramPlane,
  FaTwitterSquare,
} from 'react-icons/fa';

const Footer = () => {
  return (
    <div className='max-w-[1240px] mx-auto py-16 px-4 grid lg:grid-cols-3 gap-8 text-gray-300'>
      <div>
        <Link to='/'><h1 className='w-full text-3xl font-bold text-[#3dffb1]'>$GW</h1></Link>
        <p className='py-4'>Discover Gyrowin – where finance meets gaming on powerful blockchains. Engage with our innovative token ecosystem, contribute to charity, and watch your investment flourish.</p>
        <div className='flex justify-between md:w-[75%] my-6'>
        <Link to="https://t.me/+4_-1ue8D9xhmMWZl"><FaTelegramPlane size={30} /></Link>
            <Link to="#"><FaInstagram size={30} /></Link>
            <Link to="#"><FaTwitterSquare size={30} /></Link>
            <Link to="#"><FaGithubSquare size={30} /></Link>
            <Link to="#"><FaDribbbleSquare size={30} /></Link>
        </div>
      </div>
      <div className='lg:col-span-2 flex justify-between mt-6'>
    <div>
        <h6 className='font-medium text-gray-400'></h6>
        <ul>
            <li className='py-2 text-sm'></li>
            <li className='py-2 text-sm'></li>
            
        </ul>
    </div>
    <div>
        <h6 className='font-medium text-gray-400'>Support</h6>
        <ul>
            <li className='py-2 text-sm'></li>
            <Link to='/Documentation'><li className='py-2 text-sm'>Documentation</li></Link>
            <Link to='/About'><li className='py-2 text-sm'>About</li></Link>
            <Link to="#"><li className='py-2 text-sm'>API Status</li></Link>
            
        </ul>
    </div>
    <div>
        <h6 className='font-medium text-gray-400'>Links</h6>
        <ul>
        <Link to="https://www.gyro.win/"><li className='py-2 text-sm'>Gyro.win</li></Link>
            <Link to="https://docs.google.com/forms/d/e/1FAIpQLSdoJAQLdJNOLCn8YC7lnCgN5xU4DQAWWQ0lEU5zwF62H2Wsuw/viewform"><li className='py-2 text-sm'>Contact us</li></Link>
            <li className='py-2 text-sm'></li>
            <li className='py-2 text-sm'></li>
            <li className='py-2 text-sm'></li>
        </ul>
    </div>
    <div>
      <div>
      <img src={Logo} alt='wp' className='h-[150px] w-[150px]'></img>
      </div>
        <h6 className='font-medium text-gray-400'></h6>
        <ul>
            <li className='py-2 text-sm'></li>
            <li className='py-2 text-sm'></li>
            <li className='py-2 text-sm'></li>
        </ul>
    </div>
      </div>
    </div>
  );
};

export default Footer;
