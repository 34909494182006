import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Analytics from './components/Analytics';
import Cards from './components/Cards';
import Footer from './components/Footer';
import Hero from './components/Hero';
import Navbar from './components/Navbar';
import Newsletter from './components/Newsletter';
import About from './components/About'; // import the About component
import Documentation from './components/Documentation';

function App() {
  return (
    <Router>
      <div>
        <Navbar />
         <Routes>
          <Route path="/about" element={<About />} /> {/* This will only show when the URL path is '/about' */}
          <Route path="/Documentation" element={<Documentation />} /> {/* This will only show when the URL path is '/Documentation' */}
          <Route path="/" element={<> {/* Wrap existing components in an empty fragment */}
            <Hero />
            <Analytics />
            <Newsletter />
            <Cards />
            
          </>} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
