import React from 'react';
import Typed from 'react-typed';
import Logo from '../assets/logo512.png'
import { Link } from 'react-router-dom';

const Hero = () => {
  return (
    <div className='text-white'>
<div className='flex items-end justify-center mt-[10px]'>
  <img className='flex w-[200px] h-[200px] items-end justify-center' src={Logo} alt='Gyrowin Referrals Program'></img>
</div>

      <div className='max-w-[800px] mt-[-150px] w-full h-screen mx-auto text-center flex flex-col justify-center'>
        <p className='text-[#3dffb1] font-bold p-2'>
          A fun way to play, earn and invest! limited spots available
        </p>
        <h1 className='md:text-7xl sm:text-6xl text-4xl font-bold md:py-6'>
          Gyrowin Referrals
        </h1>
        <div className='flex justify-center items-center'>
          <p className='md:text-5xl sm:text-4xl text-xl font-bold py-4'>
            Exclusive benefits:  
          </p>
          <Typed
          className='md:text-5xl sm:text-4xl text-xl font-bold md:pl-4 pl-2'
            strings={['Play to Earn', 'Investment', 'Gaming', "Discounts"]}
            typeSpeed={120}
            backSpeed={140}
            loop
          />
        </div>
        <p className='md:text-2xl text-xl font-bold text-gray-500'>Boost your experience with our Gyrowin Referrals Program </p>
        <Link to="https://docs.google.com/forms/d/e/1FAIpQLSdUbprBLId0tIrM8_T6Xp4tky7AvGQG27mxGGICaV8-OeQUSg/viewform"><button className='bg-[#3dffb1] w-[200px] rounded-md font-medium my-6 mx-auto py-3 text-black'>Get Started</button></Link>
      </div>
    </div>
  );
};

export default Hero;
